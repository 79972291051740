<template>
  <v-container fluid>
    <v-card v-if="user">
      <v-toolbar color="indigo lighten-5" flat>
        <BackButton />
        <v-toolbar-title>{{ user.first_name }} {{ user.last_name }}</v-toolbar-title>
        <v-chip v-if="getStatus" class="ml-3" :text-color="getStatus.color" small>
          <v-avatar left>
            <v-icon :color="getStatus.color" size="20">{{ getStatus.icon }}</v-icon>
          </v-avatar>
          <span class="text-caption">{{ getStatus.title }}</span>
        </v-chip>
        <v-spacer></v-spacer>
        <div class="font-italic font-weight-medium">Профіль користувача</div>

        <template v-slot:extension>
          <v-tabs v-model="currentTab" align-with-title>
            <v-tabs-slider color="primary"></v-tabs-slider>

            <v-tab v-for="(item, index) in tabs" :key="index">
              {{ item.title }}
            </v-tab>
          </v-tabs>
        </template>
      </v-toolbar>

      <v-tabs-items v-model="currentTab">
        <v-tab-item v-for="(item, index) in tabs" :key="index">
          <v-card flat color="blue-grey lighten-5">
            <component
              :is="item.component"
              :user="user"
              :isSelf="true"
              :bonuses="bonuses"
              :employers="employers"
              :isDataSaved="isDataSaved"
              :currentTabName="currentTabName"
              :isLoaded="isLoaded"
              :editCard="editCard"
              @saveData="saveData"
            ></component>
            <v-container v-show="showEditButtons" fluid>
              <div class="d-flex justify-end">
                <v-btn @click="editCard = !editCard" class="mr-2" small>
                  {{ !editCard ? 'Редагувати' : 'Повернутись' }}
                </v-btn>
                <v-btn v-show="editCard" @click="showModal = true" :disabled="isFileLoading" color="primary" small
                  >Зберегти</v-btn
                >
              </div>
            </v-container>
          </v-card>
        </v-tab-item>
      </v-tabs-items>
    </v-card>

    <v-card v-if="currentCompanyId && hasAnyRequiredRole && currentTabName === 'info'" class="mt-5" flat>
      <Logs :preselected-users="[+user.id]" disable-users />
    </v-card>

    <v-card v-if="currentTabName === 'bonuses'" class="mt-5" flat>
      <BonusLogs />
    </v-card>

    <ConfirmActionModal @closeModal="showModal = false" :showModal="showModal">
      <template v-slot:title>Зберегти зміни?</template>
      <template v-slot:text>Дані будуть оновлені</template>
      <template v-slot:buttons>
        <v-btn @click="isDataSaved = true" color="primary" text>Так</v-btn>
        <v-btn @click="showModal = false" text>Нi</v-btn>
      </template>
    </ConfirmActionModal>
  </v-container>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import ConvertStatusesTypes from '@/mixins/convertStatusesTypes'
import BackButton from '@/components/common/BackButton'
import Logs from '@/components/common/Logs'
import BonusLogs from '@/components/common/BonusLogs'

export default {
  name: 'Profile',

  mixins: [ConvertStatusesTypes],

  components: {
    BonusLogs,
    Logs,
    BackButton,
    ConfirmActionModal: () => import('@/components/dialogs/ConfirmActionModal'),
    GeneralInfo: () => import('@/components/common/users/UserGeneralInfo'),
    Bonuses: () => import('@/components/common/users/Bonuses'),
  },

  data() {
    return {
      showModal: false,
      currentTab: 0,
      isDataSaved: false,
      isLoaded: false,
      editCard: false,
      tabs: [
        {
          name: 'info',
          title: 'Інфо',
          component: 'GeneralInfo',
        },
        {
          name: 'bonuses',
          title: 'Ваші бонуси',
          component: 'Bonuses',
        },
      ],
    }
  },

  computed: {
    ...mapState('profile', ['userRoles', 'user', 'employers', 'isFileLoading']),
    ...mapState('bonuses', ['bonuses']),
    ...mapState('companies', ['currentCompany']),
    ...mapState('dictionaries', ['rolesDictionary']),

    currentCompanyId() {
      return this.currentCompany?.id || null
    },
    currentTabName() {
      return this.tabs[this.currentTab].name
    },
    showEditButtons() {
      return this.currentTab === 0
    },
    hasAnyRequiredRole() {
      return !this.userRoles.includes(this.rolesDictionary.ACCOUNTANT)
    },
  },

  mounted() {
    this.initialize()
  },

  methods: {
    ...mapActions('profile', ['updateUser']),
    ...mapActions('logs', ['displayWarningAlert']),
    ...mapActions('bonuses', ['getBonuses']),

    async initialize() {
      this.isLoaded = true
      await this.getBonuses()
    },

    async saveData(updatedUser) {
      delete updatedUser.id
      delete updatedUser.avatar
      this.showModal = false
      this.isLoaded = false
      if (updatedUser.locale !== this.user.locale) {
        localStorage.setItem('currentLocale', updatedUser.locale)
      }
      await this.updateUser(updatedUser)
      await this.initialize()
      this.isDataSaved = false
      this.isLoaded = true
      this.editCard = false
    },
  },
}
</script>

<style scoped></style>
